.account-container {
    display: flex;
    flex-direction: column;

    h3 {
        color: $color-white;
        text-transform: none;
        font-size: 1.2rem;
    }

    .account__row {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 2px solid $color-black-light-light;

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border: none;
        }

        &.h {
            display: flex;
            align-items: center;
        }
    }

    .account__photo {
        position: relative;
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        overflow: hidden;
        border-radius: 50%;
    }

}


.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    position: relative;

    &:last-of-type, &:only-of-type {
        margin-bottom: 0;
    }

    label {
        color: $color-light;
        font-weight: 600;
        font-size: .8rem;
        margin-bottom: .4rem;
    }

    .input__item {
        background: white;
        border-radius: 4px;
        padding: .4rem 1rem;
        height: 45px;
        font-size: .8rem;
        font-weight: 400;
        font-family: $font-primary;
        border: none;
        color: $color-black-light-light;
        outline: none;

        &.textarea {
            border-radius: 4px;
            padding: 0.6rem 1rem;
            min-height: 100px;
            line-height: 1.4rem;
        }

        &.error {
            border: 2px solid $color-danger;
        }
    }

    .input-popup {
        padding: .3rem;
        background: $color-white;
        border-radius: 5px;
        //margin-bottom: 1rem;
        text-align: left;
        max-width: 15rem;
        max-height: 184px;
        overflow: auto;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, .2);
        z-index: 1;

        &::-webkit-scrollbar {
            width: 10px;
            margin-right: 20px;
        }

        &::-webkit-scrollbar-track {
            background: $color-white-light;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-light;
            border-radius: 5px
        }

        &__heading {
            margin: 0 0.5rem .2rem .5rem;

            h3 {
                font-size: .7rem !important;
                color: $color-info;
                font-weight: 600;
            }
        }

        &__items {

            .popup-list {
                padding: 0 !important;
                margin: 0 !important;

                &__item {
                    display: flex;
                    align-items: center;
                    padding: .3rem;
                    border-radius: 4px;
                    cursor: pointer;


                    &:hover, &.active {
                        background-color: $color-white-light;
                    }

                    &__img {
                        width: 27px;
                        height: 27px;
                        position: relative;
                        overflow: hidden;
                        border-radius: 50%;
                        margin-right: .4rem;
                        flex-shrink: 0;
                    }

                    &__content {
                        width: 100%;
                        overflow: hidden;

                        h4 {
                            font-size: .7rem;
                            font-weight: 500;
                            color: $color-black;
                            margin: 0;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        p {
                            font-size: .7rem;
                            color: $color-gray;
                        }
                    }
                }
            }
        }
    }

    .input-mock {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        display: inline-block;
    }
}

.rating-thumbs {
    border: 1px solid $color-gray;
    border-radius: 5px;
    padding: .6rem;


    &__artists {
        display: flex;
        justify-content: space-between;
        margin-bottom: .6rem;

        &:last-of-type {
            margin-bottom: 0;
        }

        .artists__name {
            color: $color-white;
            font-size: .8rem;
        }

        .artists__thumbs {
            display: flex;

            .checkmark {
                flex-basis: 0;
                flex-shrink: 0;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all .2s;

                i {
                    font-size: .9rem;
                }

                &.like {
                    margin-right: .4rem;
                    background: $color-success;
                }

                &.dislike {
                    background: $color-danger;
                }
            }
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark {
                transform: scale(1.3);
                box-shadow: 3px 3px 10px rgb(0, 0, 0, 1);
            }
        }
    }
}

.error-msg {
    font-size: .8rem;
    color: $color-danger;
    margin-top: .2rem;
    display: inline-block;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    user-select: none;

    @media (max-width: 768px) {
        font-size: .9rem;
    }

    a {
        color: $color-black;

        &:hover {
            text-decoration: underline;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $color-white;
        border-radius: 3px;
        border: 1px solid $color-white;

        &.round {
            border-radius: 50%;
        }

        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:after {
            left: 6px;
            top: 2px;
            width: 4px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    &:hover input ~ .checkmark {
        background-color: $color-white;
        border: 1px solid $color-white;
    }

    input:checked ~ .checkmark {
        background-color: $color-primary;
        border: 1px solid $color-primary;
    }

    .checkbox--detail {
        font-size: .9rem;
        font-weight: 300;
    }
}

.inputs-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    label {
        width: 33%;
        margin-bottom: 1rem;
    }
}

@media (max-width: 768px) {

    .account-container {

        h3 {
            font-size: 1rem;
        }

        .account__photo {
            width: 60px;
            height: 60px;
        }

    }

    .input-container {

        label {
            font-size: .7rem;
        }

        .input__item {
            height: 35px;
            font-size: .7rem;
        }
    }
}
