.editor-menu {
  padding: .4rem;
  border-radius: 5px;
  background: rgba(255, 255, 255, .2);
  display: flex;

  button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .2rem;

    &.is-active {
      background: $color-black-light-light;
      color: $color-primary;
      border-radius: 3px;
    }
  }
}

.ProseMirror {
  background: white;
  border-radius: 4px;
  padding: 0.4rem 1rem;
  height: 200px;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: "Poppins", Arial, sans-serif;
  border: none;
  color: #222;
  outline: none;
  overflow: auto;

  p {
    color: #222;
    margin-bottom: .4rem;
  }

  ul, ol {
    padding-left: 2rem;
    color: #222;
  }

  ul {
    list-style: initial;
    color: #000;
  }
}