.cast {

    &.top-cast {

        .cast-container {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid $color-gray;
            padding-bottom: 1rem;
            margin-bottom: 1rem;

            .cast-item {
                display: flex;
                align-items: center;
                width: calc(50% - .5rem);
                margin-right: 1rem;
                margin-bottom: 1rem;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }

                &__old {
                    position: absolute;
                    z-index: 1;
                    top: 20px;
                    left: -50px;
                    width: 200%;
                    height: 20px;
                    background: $color-danger-light;
                    color: $color-danger;
                    transform: rotate(-45deg);
                    font-size: .7rem;
                    font-weight: 800;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &__img {
                    margin-right: 1rem;
                    width: 80px;
                    height: 80px;
                    overflow: hidden;
                    border-radius: 5px;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &__name {
                    line-height: 1.8rem;
                    display: flex;
                    flex-direction: column;

                    .name {
                        color: $color-primary;
                        font-size: 1.2rem;
                        font-weight: 600;
                    }

                    .role {
                        font-size: .9rem;
                    }
                }
            }
        }
    }
}

.production {

    &-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        &.row {
            flex-direction: row;

            .production__item {
                margin-right: 10px;
                position: relative;
            }
        }

        .production__item {
            font-size: .9rem;
            //font-weight: 600;
            display: inline-block;
            margin-bottom: .5rem;

            span {
                font-style: italic;
                color: $color-light;
                margin-right: .2rem;
            }

            a {
                color: $color-white;
                position: relative;

                &:after {
                    content: ',';
                    margin-right: 3px;
                }

                &:last-of-type {

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .cast {

        &.top-cast {

            .cast-container {

                .cast-item {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
