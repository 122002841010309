.gallery-container {
  display: flex;
  flex-wrap: wrap;

  .gallery__item {
    width: calc(33.3% - .3rem);
    margin-right: .45rem;
    margin-bottom: .45rem;
    height: 100px;
    overflow: hidden;
    position: relative;

    &:hover {

      .gallery__thumb {
        transform: rotate(5deg) scale(1.1);
      }
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    .gallery__thumb {
      transition: all .2s;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: $color-white;
    }
  }
}
