* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;

}

body {
    background-color: $color-black;
    font-family: $font-primary;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, p, ul, span {
    color: $color-white;
}

button, input {
    border: unset;
    box-shadow: none;
    outline: unset;
    background: unset;
    font: unset;
}

a {
    color: unset;
    text-decoration: none;
}

section {
    padding: 2rem 0;

    &:first-of-type {
        padding-top: 0;
    }
}

.wrapper {
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: 2rem auto;

    &-main {
        width: 70%;

        &.full {
            width: 100%;
        }

        &.left {
            padding-right: 3rem;
        }

        &.right {
            padding-left: 3rem;
        }
    }
}

h3 {
    font-size: 1.5rem;
    font-weight: 700;
    color: $color-primary;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    i {
        color: $color-white;
        margin-right: 12px;
        font-size: 1rem;
    }
}

h4 {
    color: $color-white-light;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

p {
    margin-bottom: 1rem;

    &:only-of-type, &:last-of-type {
        margin-bottom: 0;
    }
}

ul, ol {
    padding-left: 2rem;
    margin-bottom: 1rem;
    color: $color-white;
}

ul {
    list-style: initial;
}

.quick-view {
    max-height: 150px;
    overflow: hidden;
    position: relative;
    transition: all .2s;

    &:after {
        content: '';
        height: 100px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    }
}

.full-view {
    height: auto;
    transition: all .2s;

    p[data-f-id=pbf] {
        display: none;
    }
}

button {

    .fal.fa-heart {
        color: $color-white !important;
    }

    .fas.fa-heart {
        color: $color-danger !important;
    }

    .fas.fa-star {
        color: $color-primary !important;
    }
}


@media (max-width: 1200px) {

    .wrapper {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (max-width: 1000px) {

    .wrapper {
        margin-top: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
        flex-wrap: wrap;

        &-main {
            width: 100% !important;
            padding: 0 !important;
        }
    }

    h3 {
        font-size: 1.2rem;

        i {
            margin-right: 8px;
            font-size: .8rem;
        }
    }
}
