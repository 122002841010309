.footer {
  margin-top: 5rem;
  padding-bottom: 90px; //toto odstranit, ked zmizne fixny alert

  a {
    color: $color-white;
  }

  &__logo {
    text-align: center;

    a {
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  &-social {
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    &__bg {
      margin-right: .5rem;
      background-color: $color-white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .2s;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        opacity: .9;
      }

      i {
        font-size: 1.2rem;
        color: $color-black;
      }
    }

  }

  &-menu {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    list-style: none;

    &__item {
      margin-right: 2rem;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        font-size: .8rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .footer {
    margin-top: 0;
    text-align: center;

    &-menu {
      margin-top: 1rem;
      flex-wrap: wrap;
      flex-direction: column;

      &__item {
        margin-right: 0;
      }
    }
  }
}