.navbar {
    color: $color-white;
    height: 120px;
    background: $color-black-light;
    //background: red;

    &-wrapper {
        max-width: 1200px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-item {
        display: flex;

        &__logo {
            font-size: 1.95rem;
            margin-right: 3rem;
            text-transform: uppercase;
            font-weight: 700;
        }

        &__menu {
            margin-right: 3rem;
            display: flex;
        }


        &__toggle {
            display: flex;
            align-items: center;
            color: $color-white;

            .toggle__button {
                display: flex;
                flex-direction: column;

                span {
                    width: 30px;
                    height: 2px;
                    border-radius: 5px;
                    margin-bottom: 5px;
                    background: $color-primary;
                    display: inline-block;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            .toggle__text {
                font-size: .9rem;
                font-weight: 500;
                margin-left: 10px;
                text-transform: uppercase;
            }
        }

        &__search {
            width: 550px;
            height: 50px;
            position: relative;

            .search__input {
                background: $color-black-light-light;
                border-radius: 5px;
                width: 100%;
                height: 100%;
                padding: 0 15px;
                color: $color-gray;
                font-weight: 400;
                transition: all .2s;

                &:focus {
                    background: $color-white;
                    box-shadow: 2px 2px 4px $color-black;
                }
            }

            .search-icon {
                position: absolute;
                top: 11px;
                right: 20px;
                font-size: 1.2rem;
                color: $color-light;
            }
        }

        &__login {
            display: flex;
            align-items: center;
            position: relative;

            .login__icon {
                color: $color-primary;
                font-size: 2rem;
                margin-right: 10px;
                display: flex;

                .login__img-container {
                    height: 50px;
                    width: 50px;
                    overflow: hidden;
                    position: relative;
                    border-radius: 50%;
                    background: $color-black;

                    img {
                        position: absolute;
                        top: 20%;
                        left: 10%;
                        right: 10%;
                        bottom: 10%;
                        margin: auto;
                        width: 70%;
                        height: 70%;
                        object-fit: cover;
                    }
                }



                &.search {
                    display: none;
                }
            }

            .login__text {
                display: flex;
                flex-direction: column;
                font-size: .9rem;
                line-height: 1.2rem;
                text-transform: uppercase;
                box-sizing: content-box;

                a {
                    transition: all .2s;

                    &:first-of-type {
                        font-weight: 700;
                    }

                    &:last-of-type {
                        font-weight: 500;
                    }

                    &:hover {
                        opacity: .8;
                    }
                }

                button {

                    i {
                        margin-left: 10px;
                        color: $color-light;
                    }
                }
            }

            .dropdown {
                position: absolute;
                top: 100%;
                right: 0;
                width: 200px;
                height: auto;
                background: $color-black-light-light;
                z-index: 11;
                display: none;
                border-radius: 5px;

                &.open {
                    display: block;
                }

                ul {
                    list-style: none;
                    padding: 5px;
                    margin: 0;

                    li {

                        &.special {
                            border-top: 1px solid $color-gray;
                            border-bottom: 1px solid $color-gray;
                            margin: .2rem 0;

                            a {
                                margin: .2rem 0;
                            }

                        }

                        a {
                            padding: 5px 10px;
                            border-radius: 3px;
                            display: block;
                            font-size: .8rem;

                            &:hover {
                                background: $color-black-light;
                            }
                        }

                        &:last-of-type {

                            a {
                                color: $color-danger;
                            }
                        }
                    }
                }
            }
        }
    }

    &-dropdown {
        position: fixed;
        top: 120px;
        left: 0;
        width: 100%;
        height: 0;
        background: $color-black;
        z-index: 999;
        overflow: hidden;
        transition: all .2s;

        &.open {
            height: calc(100vh - 120px);
        }

        &-container {
            max-width: 1200px;
            width: 100%;
            height: calc(100% - 100px);
            margin: 50px auto;
            display: flex;
            text-align: left;

            .dropdown__close {
                position: absolute;
                right: 20px;
                top: 20px;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $color-primary;
                border-radius: 50%;
                z-index: 1;

                i {
                    font-size: 1.4rem;
                    color: $color-black;
                }
            }

            .dropdown__column {
                width: calc(33.3% - 1rem);
                margin-right: 1.5rem;
                position: relative;

                &:last-of-type {
                    margin-right: 0;
                }

                h2 {
                    font-size: 1.6rem;
                    font-weight: 600;
                    color: $color-primary;
                    margin-bottom: 1rem;

                    img {
                        width: 30px;
                        position: absolute;
                        left: 0;
                        top: 5px;

                        &.xl {
                            width: 40px;
                        }
                    }

                    span {
                        padding-left: 50px;
                    }

                }

                &__list {
                    color: red;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        margin-bottom: 1rem;

                        a {
                            color: $color-white;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {

    .navbar {

        &-wrapper {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

@media (max-width: 1000px) {

    .navbar {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 60px;
        background: $color-black-light;
        z-index: 6;

        &-item {

            &__logo {
                order: 2;
                font-size: 1.5rem;
                margin-right: 1rem;
            }

            &__toggle {
                order: 1;
                margin-right: 1rem;

                .toggle__button {

                    span {
                        width: 22px;
                        height: 2px;
                    }
                }

                .toggle__text {
                    display: none;
                }
            }

            &__search {
                position: fixed;
                top: 5px;
                left: 5px;
                width: calc(100% - 10px);
                height: 50px;
                display: none;
                z-index: 1;

                &.active {
                    display: block;
                }

                button {

                    &.btn--search {
                        display: none;
                    }

                    &.btn--clear {
                        display: block;
                    }
                }
            }

            &__login {

                .login__icon {
                    font-size: 1.4rem;
                    margin-left: 1rem;
                    margin-right: 0;

                    &.search {
                        display: block;
                    }

                    &:first-of-type {
                        margin-left: 0;
                    }
                }

                .login__text {
                    display: none;
                }
            }
        }

        &-dropdown {
            top: 0;
            left: -100%;
            width: 100%;
            height: 100vh !important;
            background: rgba(0, 0, 0, .9);

            &.open {
                left: 0;
            }

            &-container {
                margin: 30px auto 30px auto;
                flex-wrap: wrap;
                padding: 0 2rem;

                .dropdown__close {
                    width: 30px;
                    height: 30px;

                    i {
                        font-size: 1rem;
                    }
                }

                .dropdown__column {
                    width: 100%;
                    margin-right: 0;

                    h2 {
                        font-size: 1.2rem;

                        img {
                            width: 20px;

                            &.xl {
                                width: 30px;
                            }
                        }

                        span {
                            padding-left: 40px;
                        }

                    }

                    &__list {
                        padding-left: 40px;

                        li {
                            margin-bottom: .6rem;
                            line-height: 1rem;

                            a {
                                font-size: .8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
