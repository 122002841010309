.modal-open {
    overflow: hidden;
}

.modal__backdrop {
    position: fixed;
    z-index: 12;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    animation: modal-backdrop-animation .5s;
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.5);

    &.closing {
        opacity: 1;
        animation: modal-backdrop-closing-animation .5s;
        animation-fill-mode: forwards;
        animation-delay: .5s;

    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal__dialog {
    height: calc(100% - 6rem);
    margin: 3rem auto;
    position: relative;
    width: auto;
    border-radius: 5px;
    z-index: 1051;
    //opacity: 0;
    animation: modal-content-animation .5s;
    animation-fill-mode: forwards;

    &.modal--s {
        width: 550px;
    }


    &.closing {
        animation: modal-content-closing-animation .5s;
        animation-fill-mode: forwards;
        animation-delay: .15s;
    }

    .modal__content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 100%;
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 5px 5px 50px rgba(0, 0, 0, .1);
    }

    .modal__close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        border: none;
        cursor: pointer;
        z-index: 99;

        &:focus {
            outline: none;
        }

        .fal {
            font-size: 2rem;
            color: $color-gray !important;
        }
    }

    .modal__body {
        background: $color-black-light-light;
        border-radius: 0;
        max-height: 100%;
        min-height: 100px;
        overflow-y: auto;
        text-align: center;
        padding: 3rem;

        h3 {
            font-size: 1.4rem;
            display: unset;
        }

        .modal__icon--top {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -1rem;

            img {
                height: 8rem;
            }

            .fa--original {
                color: #FFD700;
                z-index: 1;
            }
        }

        .modal__rating {
            margin-top: 2rem;

            button {

                i {
                    font-size: 1.6rem;
                    color: $color-gray;
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .modal {
        position: fixed;
        z-index: 1000;

        .modal__dialog {


            &.modal--s {
                width: 90%;
            }

        }
    }
}


@keyframes modal-backdrop-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes modal-backdrop-closing-animation {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes modal-content-animation {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes modal-content-closing-animation {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-50px);
    }
}
