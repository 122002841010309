.label {
  padding: .3rem 1.2rem;
  border-radius: 50px;
  font-size: .7rem;
  font-weight: 600;

  &--s {
    padding: .3rem .8rem;
    font-size: .7rem;
    font-weight: 400;
    letter-spacing: .4px;

  }

  &--danger {
    background: $color-danger;
    color: $color-white;
  }

  &--danger-inv {
    border: 1px solid $color-danger;
    background: transparent;
    color: $color-white;
  }

  &--primary {
    background: $color-primary;
    color: $color-white;
  }

  &--gray-inv {
    border: 2px solid $color-gray;
    background: transparent;
    color: $color-white;
  }
}

@media (max-width: 768px) {

  .label {
    padding: .2rem 1rem;
    font-size: .7rem;
  }
}