.theaters-container {

  .item__more {
    position: absolute;
    left: 0;
    top: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $color-gray;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      color: $color-black;
      font-size: 1rem;
    }
  }

  .theaters__item {
    display: flex;
    border-bottom: 2px solid $color-gray;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    &:last-of-type, &:only-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &__city {
      width: 30%;
      margin-right: 3rem;
      position: relative;

      h2 {
        font-size: 1.4rem;
        color: $color-primary;
        padding-left: 35px;
      }
    }

    &__theater {
      flex-grow: 1;

      p {
        font-size: 1rem;
        color: $color-white;
        margin-bottom: .4rem;

        &:last-of-type, &:only-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

}