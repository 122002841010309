.desk {

  &__item {
    margin-bottom: .4rem;
    display: flex;
  }

  &__day {
    font-weight: 700;
    width: 150px;
    text-transform: capitalize;
  }

  &__time {
    flex-grow: 1;


    span {
      position: relative;
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;

        &:after {
          content: '';
        }
      }

      &:after {
        content: ',';
        position: absolute;
        right: -5px;
        top: 0;

      }
    }
  }
}