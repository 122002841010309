@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

$font-primary: 'Poppins', Arial, sans-serif;

$color-black: #000;
$color-black-light: #191919;
$color-black-light-light: #222;
$color-white: #fff;
$color-white-light: #eee;
$color-white-light-light: #f2f2f2;
$color-white-light-dark: #ddd;
$color-primary: #D59F4C;
$color-light: #999999;
$color-gray: #333;
$color-danger: #EF4444;
$color-danger-light: #FFA8A8;
$color-info-light: #A8DEFF;
$color-info: #0582CA;
$color-success-light: #B0E6B2;
$color-success: #059669;
$color-gold: #F8DC25;

