@import "global/variables";
@import "global/alert";
@import "global/modifiers";
@import "global/global";
@import "global/tooltip";
@import "modules/news";
@import "modules/navbar";
@import "modules/sidebar";
@import "modules/quick-items";
@import "modules/ratings";
@import "modules/btns";
@import "modules/labels";
@import "modules/cast";
@import "modules/gallery";
@import "modules/performances";
@import "modules/header";
@import "modules/theaters";
@import "modules/auth";
@import "modules/forms";
@import "modules/modals";
@import "modules/search";
@import "modules/slider";
@import "modules/table";
@import "modules/footer";
@import "modules/popup";
@import "modules/editor";
@import "theatre/gallery";
@import "theatre/desk";
@import "modules/carousel";


@import "tiny-slider/dist/tiny-slider.css";
