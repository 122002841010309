.slider {
  position: relative;

  &__btn {
    color: $color-white;
    position: absolute;
    top: 0;
    width: 80px;
    height: 100%;
    z-index: 4;
    font-size: 2.4rem;
    opacity: .5;
    transition: all .2s;

    &:hover {
      opacity: 1;
    }
  }

  &--prev {
    left: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0));
  }

  &--next {
    right: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, .7));
  }
}

@media (max-width: 768px) {

  .slider {

    &--prev {
      left: -1rem;
    }

    &--next {
      right: -1rem;
    }
  }
}