#flashes {

    &.alert {
        position: fixed;
        width: 300px;
        top: 10px;
        right: 10px;
        z-index: 1000;
        animation: flash-animation 8s;
        animation-fill-mode: forwards;
        opacity: 1;
    }
}

.alert {
    border-radius: 5px;
    font-weight: 600;

    &--fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: 0;
        z-index: 1000;
    }

    &--primary {
        background: $color-primary;
        color: $color-black;

        .alert__content {

            p {
                color: $color-black;
            }
        }
    }

    &--danger {
        background: $color-danger;
        color: $color-white;

        .alert__content {

            p {
                color: $color-white;
            }
        }
    }

    &--success {
        background: $color-success;
        color: $color-white;

        .alert__content {

            p {
                color: $color-white;
            }
        }
    }

    &--icon {
        display: flex;
        align-items: center;

        .alert__icon {
            margin-right: 1rem;
        }

        .alert__content {

            p {
                font-weight: 500;
            }
        }
    }

    &--s {
        padding: .7rem;

        .alert__icon {

            i {
                font-size: 1rem;
            }
        }

        .alert__content {

            p {
                font-size: .8rem;
                line-height: 1.2rem;
            }
        }
    }

    &--m {
        padding: 1rem;

        .alert__icon {

            i {
                font-size: 2.2rem;
            }
        }

        .alert__content {

            p {
                font-size: 1.1rem;
                line-height: 1.8rem;
            }
        }
    }
}

@media (max-width: 768px) {

    .alert {
        padding: .8rem;

        &--icon {

            .alert__icon {
                display: none;
            }

            .alert__content {

                p {
                    font-size: .8rem;
                    line-height: 1.1rem;
                }
            }
        }
    }
}

@keyframes flash-animation {
    0% {
        opacity: 0;
        top: -100px;
    }
    15% {
        opacity: 1;
        top: 10px;
    }

    85% {
        opacity: 1;
        top: 10px;
    }

    100% {
        opacity: 0;
        top: -100px;
    }
}
