.tns-nav {
    display: none;
}

.quick-items-controls {
    position: absolute;
    width: 100%;
    top: calc(50% - 10px);
    z-index: 1;

    .quick-items-prev {
        position: absolute;
        left: -18px;
        width: 35px;
        height: 50px;
        border-radius: 10px;
        background: rgba(0, 0, 0, .7);
        border: 1px solid rgba(255, 255, 255, .4);
        display: flex;
        justify-content: center;
        align-items: center;

        &:disabled {
            display: none;
        }

        i {
            color: $color-white;
            font-size: 1.4rem;
        }
    }

    .quick-items-next {
        position: absolute;
        right: -18px;
        width: 35px;
        height: 50px;
        border-radius: 10px;
        background: rgba(0, 0, 0, .7);
        border: 1px solid rgba(255, 255, 255, .4);
        display: flex;
        justify-content: center;
        align-items: center;

        &:disabled {
            display: none;
        }

        i {
            color: $color-white;
            font-size: 1.4rem;
        }
    }
}
