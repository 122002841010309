.quick-items-container {
    //margin-top: 3rem;
    color: $color-white;
    position: relative;
}

.quick-items {
    display: flex;

    &.vertical {
        flex-direction: column;

        .quick-item {
            margin-bottom: .6rem;
            align-items: stretch;
            height: 195px;
            background: $color-black-light;

            &:hover {
                background: lighten($color-black-light, 3%);
            }


            &__thumb {
                width: 135px;
                height: auto;
                margin-right: 1rem;
                border-radius: 3px 0 0 3px;
            }

            &__content {
                display: flex;
                flex-direction: column;
                padding: .6rem .6rem .6rem 0;
                width: calc(100% - 110px - 1rem);

                .item__top {
                    order: 2;

                    .item__name {
                        font-size: 1rem;
                        line-height: 1.2rem;
                        font-weight: 500;
                        max-width: 100%;
                        margin-bottom: 0;
                        display: block;

                        &.theater {
                            margin-top: .4rem;
                            font-size: .7rem;
                            line-height: .9rem;
                        }

                        &.premiere-date {
                            font-size: .7rem;
                            color: $color-primary;
                            font-weight: 600;
                            margin-bottom: .2rem;
                        }
                    }

                    .item__desc {
                        font-size: .7rem;
                        line-height: .9rem;
                    }
                }

                .item__bottom {
                    order: 1;
                    justify-content: space-between;
                    margin-top: 5px;
                }
            }

            &:last-of-type, &:only-of-type {
                margin-bottom: 0;
            }
        }
    }

    &.horizontal {
        /*flex-direction: row;
        flex-wrap: wrap;*/

        .quick-item {
            /*flex-direction: column;
            margin-right: 1rem;
            width: calc(20% - .8rem);

            &:nth-of-type(5n) {
                margin-right: 0;
            }*/

            &__thumb {
                width: 100%;
                height: 200px;
                border-radius: 3px 3px 0 0;
                position: relative;
                background: $color-black-light;

                &.dark {

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 80px;
                        width: 100%;
                        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .9));
                        z-index: 11;
                    }

                    span {
                        color: $color-white;
                        font-size: .7rem;
                        font-weight: 700;
                        position: absolute;
                        bottom: 5px;
                        left: 5px;
                        z-index: 12;
                    }
                }

                a {
                    background: transparent;
                    z-index: 3;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }

                /*&:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 150px;
                    border-radius: 3px 3px 0 0;
                    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0));
                    z-index: 1;
                }*/
            }

            &__content {
                padding: 1rem .6rem .6rem .6rem;
                background: $color-black-light;

                &:hover {
                    background: lighten($color-black-light, 3%);
                }

                .item__top {
                    height: 50px;
                    display: flex;
                    align-items: center;
                    overflow: hidden;

                    a {
                        font-size: .8rem;
                        line-height: 1.2rem;
                    }

                    .item__name {
                        font-size: .8rem;
                        line-height: 1rem;
                        font-weight: 500;
                        max-width: 100%;
                    }


                }

                .item__bottom {
                    flex-direction: column;
                    margin-top: 5px;

                    .item__controls {
                        justify-content: space-between;

                        &__left {
                            display: flex;
                        }

                        &__right {
                            font-weight: 600;
                        }
                    }
                }

                .item__desc {
                    font-size: .7rem;
                }
            }

            /*&:last-of-type, &:only-of-type {
                margin-right: 0;
            }*/

        }
    }
}

.quick-item {
    display: flex;
    /*border: 1px solid $color-black-light-light;*/
    border-radius: 3px;

    &__old {
        position: absolute;
        z-index: 1;
        top: 20px;
        left: -80px;
        width: 200%;
        height: 20px;
        background: $color-danger-light;
        color: $color-danger;
        transform: rotate(-45deg);
        font-size: .7rem;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__thumb {
        flex-shrink: 0;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            //height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }

    &__duration {
        position: absolute;
        left: 15px;
        top: 15px;
        display: flex;
        align-items: center;
        z-index: 2;

        i {
            color: $color-primary;
            margin-right: 5px;
        }

        span {
            font-size: .6rem;
        }
    }

    &:last-of-type, &:only-of-type {
        margin-bottom: 0;
    }

    .item__bottom {
        display: flex;

        .item__rating {
            font-size: 1.2rem;
            line-height: 1.6rem;
            font-weight: 600;

            i {
                color: $color-gold;
            }
        }

        .item__date {
            font-size: .8rem;
            font-weight: 600;
            color: $color-primary;
        }

        .item__place {
            font-size: .7rem;
            color: $color-white;
            height: 35px;
            overflow: hidden;
        }

        .item__controls {
            display: flex;
            margin-top: 5px;

            button {
                margin-right: 10px;

                i {
                    color: $color-white;
                }

                .item__rate__value {
                    color: $color-info;
                    font-weight: 500;
                }

                &.active {

                    i {
                        color: $color-primary;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .quick-items {
        display: flex;

        &.vertical {
            flex-direction: column;

            .quick-item {
                margin-bottom: 1rem;
                height: 151px;

                &__thumb {
                    width: 85px;
                    margin-right: 1rem;
                }

                .item__desc {
                    font-size: .7rem !important;
                    line-height: .9rem !important;
                }

                &:last-of-type, &:only-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    .quick-item {

        .item__top {

            .item__rating {
                font-size: 1.4rem;
            }

            .item__date {
                font-size: .8rem;
            }

            .item__place {
                font-size: .7rem;
            }
        }

        .item__bottom {

            .item__name {
                font-size: .9rem;
            }

            .item__desc {
                font-size: .7rem;
                line-height: .9rem;
            }
        }
    }
}
