.item__stars {
    display: flex;

    i {
        font-size: .9rem;
        margin-right: 3px;
        color: $color-gray;

        &.full {
            color: $color-gold;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.ratings {
    color: $color-white;

    .rating-items-container {

        &.gray {
            background: $color-black-light;
            padding: 1rem;
            border-radius: 3px;
        }

        &.border {
            padding: 1.5rem;
            border-radius: 3px;
            border: 1px solid $color-gray;
        }

    }

    .rating-items {

        &.s {

            .rating-item {
                border-bottom: none;
                padding-bottom: .5rem;
                margin-bottom: .5rem;

                &:last-of-type {
                    border-bottom: 1px solid $color-gray;
                    padding-bottom: 1rem;
                    margin-bottom: 1rem;
                }

                .rating-item__content {

                    .item__bottom {
                        flex-wrap: wrap;
                        flex-direction: row;

                        .item__name {
                            width: 50%;
                        }

                        .item__desc {
                            order: 3;
                            width: 100%;
                        }

                        .item__stars {
                            margin-bottom: .4rem;
                            margin-top: 0;
                            width: 50%;
                            text-align: right;
                        }
                    }
                }
            }
        }

        .rating-item {
            border-bottom: 1px solid $color-gray;
            padding-bottom: 2rem;
            margin-bottom: 2rem;

            &__inner {
                display: flex;
            }

            &.l2 {
                margin-top: 2rem;
                margin-left: calc(60px + 1rem);
                padding-bottom: 0;
                position: relative;
                border-bottom: none;

                &:before {
                    content: '';
                    height: calc(100% + 2rem);
                    width: 1px;
                    background: $color-gray;
                    position: absolute;
                    left: -2rem;
                    top: 0;
                }

                &:after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: $color-primary;
                    position: absolute;
                    top: calc(30px - 5px);
                    left: calc(-2rem - 5px);
                }

                &:last-of-type {

                    &:before {
                        height: 100%;
                    }

                    &:after {
                        display: none;
                    }
                }

                &:nth-of-type(2) {

                    &:before {
                        top: 30px;
                        height: calc(100% - 30px + 2rem);
                    }
                }

            }

            &:last-of-type, &:only-of-type {
                margin-bottom: 0;
                border-bottom: none;
            }

            &__thumb {
                width: 60px;
                height: 60px;
                margin-right: 1rem;
                flex-shrink: 0;
                position: relative;
                overflow: hidden;
                border-radius: 5px;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }

            &:last-of-type, &:only-of-type {
                margin-bottom: 0;
            }

            .rating-item__content {
                flex-grow: 1;

                .item__top {
                    display: flex;

                    .item__name {
                        font-size: .8rem;
                        color: $color-white;
                        margin-right: 2rem;
                        font-weight: 600;
                    }

                    .item__controls {
                        display: flex;

                        button {
                            color: $color-primary;

                            i {
                                margin-right: 5px;
                            }

                            span {
                                font-size: .8rem;
                            }

                        }
                    }

                }

                .item__bottom {
                    display: flex;
                    flex-direction: column;
                    margin-top: 0;

                    .item__name {
                        font-size: 1rem;
                        font-weight: 500;
                        display: inline-block;
                    }

                    .item__desc {
                        font-size: .8rem;
                        line-height: 1rem;
                        margin-top: 5px;
                    }

                    .item__controls {
                        display: flex;
                        margin-top: .6rem;

                        button {
                            margin-right: 1.6rem;

                            &.button-more {
                                font-size: .8rem;
                                color: $color-light;
                                font-weight: 400;
                            }

                            &.button-reply {
                                font-size: .8rem;
                                color: $color-light;
                                font-weight: 800;
                            }
                        }
                    }
                }
            }
        }
    }
}

.rating-quick-container {
    width: 100%;
    //border: 1px solid $color-primary;
    padding: 0;
    display: flex;
    //flex-direction: column;
    //align-items: flex-end;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;


        &.show {

            &:hover {

                .tooltip {
                    display: block;
                }
            }
        }

        &.personal {
            //background: red;
            padding: 1rem;
            border: 1px solid $color-gray;
            border-radius: 5px;
            align-items: center;
            margin-left: 2rem;

            &:hover {

                .tooltip {
                    display: block;
                }
            }

            .rating-quick__rating {
                display: flex;
            }
        }
    }

    .rating-quick__rating {

        .rating-quick__icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-gold;
            color: $color-white;
            margin-right: 1rem;

            i {
                margin: 0;
                font-size: 1.3rem;
            }
        }

        i {
            font-size: 1.6rem;
            margin-right: 5px;
            margin-bottom: 10px;
        }
    }

    .rating__value {
        font-size: 1.8rem;
        font-weight: 600;
        display: inline-block;
    }

    .rating__all {
        font-size: 1.2rem;
        font-weight: 600;
        opacity: .4;
    }

    .rating__count {
        margin-top: .6rem;
        font-size: .7rem;
        display: inline-block;
    }
}

.rating-thumbs {

}

@media (max-width: 768px) {

    .rating-quick-container {
        width: 100%;
        border: 1px solid $color-primary;
        border-radius: 5px;
        padding: .6rem 1rem;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0;

        &__item {
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &.personal {
                //background: red;
                padding: 0;
                border: none;
            }
        }

        .rating__value {
            font-size: 1.4rem;
        }

        .rating__count {
            display: none;
        }
    }
}
