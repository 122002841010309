.sidebar {
    width: 30%;
    color: $color-white;

    .birthday {
        display: flex;

        &__cake {
            margin-right: 10px;

            img {
                width: 40px;
                height: auto;
            }
        }

        &__text {
            line-height: 1.2rem;

            .birthday__heading {
                font-size: .8rem;
            }

            .birthday__name {
                display: flex;
                font-size: 1.1rem;
                font-weight: 700;
                text-transform: uppercase;

                span {
                    margin-right: 5px;

                    &:last-of-type, &:only-of-type {
                        margin-right: 0;
                    }
                }
            }

        }
    }

    &__menu {
        background: $color-black-light-light;
        border-radius: 5px;
        padding: .5rem;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin-bottom: .4rem;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &.special {
                    border-top: 1px solid $color-gray;
                    border-bottom: 1px solid $color-gray;

                    a {
                        margin: .6rem 0;
                    }

                }

                &.active {

                    a {
                        background: $color-primary;
                        color: $color-black;

                        &:hover {
                            background: $color-primary;
                        }

                        i {
                            color: $color-black;
                        }
                    }
                }

                a {
                    font-size: .9rem;
                    font-weight: 600;
                    padding: .6rem 1.2rem;
                    display: block;
                    border-radius: 3px;
                    padding-left: 55px;
                    position: relative;

                    &:hover {
                        background: $color-black-light;
                    }

                    i {
                        position: absolute;
                        left: 1.2rem;
                        top: 10px;
                        font-size: 1.2rem;
                        color: $color-primary;
                    }
                }
            }
        }
    }

    &-container {

        &.gray {
            padding: 1.5rem;
            border-radius: 3px;
            background: $color-black-light;
            border: 1px solid $color-black-light-light;
        }

        &.border {
            padding: 1.5rem;
            border-radius: 3px;
            border: 1px solid $color-gray;
        }
    }
}

@media (max-width: 1000px) {

    .sidebar {
        width: 100% !important;

        &__menu {
            margin-bottom: 3rem;

            ul {
                display: flex;
                justify-content: space-between;

                li {
                    margin-bottom: 0;
                    width: 50px;
                    height: 40px;

                    &:last-of-type {

                        a {

                            i {
                                color: $color-danger;
                            }
                        }
                    }

                    &.special {
                        border: none !important;

                        a {
                            margin: 0 !important;
                        }

                    }

                    span {
                        display: none;
                    }

                    a {
                        height: 100%;
                        width: 100%;
                        font-size: 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 0;

                        &:hover {
                            background: $color-black-light;
                        }

                        i {
                            position: relative;
                            top: unset;
                            left: unset;
                        }
                    }
                }
            }
        }

        &-container {

            &.gray {
                padding: 0;
                border-radius: 3px;
                background: transparent;
            }

            &.border {
                padding: 0;
                border-radius: 3px;
                border: transparent;
            }
        }
    }
}
