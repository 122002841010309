.d--flex {
  display: flex;
}

.d--none {
  display: none !important;
}

.jc--sb {
  justify-content: space-between;
}

.jc--c {
  justify-content: center;
}

.fd--c {
  flex-direction: column;
}

.fw--w {
  flex-wrap: wrap;
}

.ai--c {
  align-items: center;
}

.as--fs {
  align-self: flex-start;
}

.w--30 {
  width: 30%;
}

.w--40 {
  width: 40% !important;
}

.w--50 {
  width: 50% !important;
}

.w--60 {
  width: 60% !important;
}

.w--70 {
  width: 70%;
}

.w--100 {
  width: 100%;
}

.m--m {
  margin: 3rem;
}

.mx--m {
  margin-right: 3rem;
  margin-left: 3rem;
}

.my--m {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mt--m {
  margin-top: 3rem;
}

.mr--m {
  margin-right: 3rem;
}

.mb--m {
  margin-bottom: 3rem;
}

.ml--m {
  margin-left: 3rem;
}

.mt--s {
  margin-top: 1.5rem !important;
}

.mb--s {
  margin-bottom: 1.5rem !important;
}

.mr--s {
  margin-right: 1.5rem;
}

.ml--s {
  margin-left: 1.5rem;
}

.mt--xs {
  margin-top: .5rem;
}

.mr--xs {
  margin-right: .5rem;
}

.mb--xs {
  margin-bottom: .5rem !important;
}

.ml--xs {
  margin-left: .5rem;
}

.mt--0 {
  margin-top: 0;
}

.pt--0 {
  padding-top: 0;
}

.pb--0 {
  padding-bottom: 0 !important;
}

.text--featured {
  color: $color-primary;
}

.text--gold {
  color: $color-gold;
}

.text--danger {
  color: $color-danger !important;
}

.text--success {
  color: $color-success !important;
}

.text--info-negative {
    color: #ef4444 !important;
}

.text--info-positive {
    color: #84cc16 !important;
}

.text--info-light {
  color: $color-info-light;
}

.text--right {
  text-align: right;
}

.text--left {
    text-align: left;
}

.text--center {
  text-align: center !important;
}

.text--s {
  font-size: .8rem;
}

.text--light {
  color: $color-light;
}

.img--bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.tagged {
  color: $color-danger-light;
}

@media (max-width: 768px) {

  .sm\:fw--w {
    flex-wrap: wrap;
  }

  .sm\:fd--c {
    flex-direction: column;
  }

  .sm\:w--100 {
    width: 100% !important;
  }

  .sm\:mt--m {
    margin-top: 1.5rem;
  }

  .sm\:mt--s {
    margin-top: 1rem;
  }

  .sm\:ml--0 {
    margin-left: 0 !important;
  }

  .sm\:mr--0 {
    margin-right: 0;
  }

  .sm\:mt--0 {
    margin-top: 0 !important;
  }

  .mb--m {
    margin-bottom: 1.5rem;
  }

  .hidden--xs {
    display: none;
  }
}






