button {
  cursor: pointer;
}

.btn {
  padding: .6rem 2rem;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
  display: inline-block;

  &--br-xl {
    border-radius: 50px;
  }

  &--primary {
    background: $color-primary;
    color: $color-white !important;

    &:hover {
      background: darken($color-primary, 5%);
    }
  }

  &--primary-inv {
    background: transparent;
    border: 2px solid $color-primary;
    color: $color-white !important;

    &:hover {
      border: 2px solid darken($color-primary, 10%);
    }
  }

  &--white {
    background: $color-white;
    color: $color-black !important;

    &:hover {
      background: darken($color-white, 5%);
    }
  }

  &--dark {
    background: $color-black-light-light;
    color: $color-white !important;

    &:hover {
      background: darken($color-black-light-light, 1%);
    }
  }

  &--danger {
    background: $color-danger;
    color: $color-white !important;

    &:hover {
      background: darken($color-danger, 5%);
    }
  }

  &--danger-inv {
    background: transparent;
    color: $color-danger !important;
    border: 2px solid $color-danger-light;

    &:hover {
      border: 2px solid $color-danger;
    }
  }

  &--s {
    padding: .4rem 1rem;
    font-size: .7rem;
  }

  &--block {
    display: block;
  }

  &--text {
    text-decoration: none;
    padding: 0;

    &.primary {
      color: $color-primary;
    }

    &.danger {
      color: $color-danger;
    }

    &.gray {
      color: $color-gray;
    }

    &.white {
      color: $color-white;

      i {
        color: $color-primary;
        margin-right: .4rem;
      }
    }

    &.s {
      font-size: .8rem;
    }
  }
}

@media (max-width: 768px) {

  .btn {
    padding: .4rem 1.4rem;
    font-size: .8rem;

    &--s {
      padding: .3rem .5rem;
      font-size: .6rem;
    }

    &--text {
      padding: 0;

      &.s {
        font-size: .7rem;
      }
    }
  }
}
