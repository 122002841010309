.tooltip {
  background: $color-gray;
  color: $color-white;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 5px;
  width: 150px;
  position: absolute;
  top: -35px;
  left: calc(50% - 75px);
  font-weight: 600;
  z-index: 1;
  text-align: center;
  display: none;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 4px);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $color-gray;
  }
}