.table {

    &-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        //border-bottom: 2px solid $color-black-light-light;

        h2 {
            font-size: 1.2rem;
        }
    }

    &-filter {
        display: flex;
        align-items: center;

        span {
            font-size: .8rem;
        }

        button {

            &.active {

                i {
                    color: $color-primary;
                }
            }
        }
    }

    &-body {
        border-radius: 5px;
        padding: 0;
        background: $color-black-light;
        border: 1px solid $color-black-light-light;
    }

    &-row {
        padding: 1rem;
        border-bottom: 1px solid $color-black-light-light;
        display: flex;
        justify-content: space-between;

        &:last-of-type {
            border-bottom: none;
        }

        &__block {
            width: 100%;
            display: flex;

            &__inner {
                display: flex;
            }

            &.l2 {
                width: auto;
                margin-top: 2rem;
                margin-left: calc(50px + 1rem);
                padding-bottom: 0;
                position: relative;
                border-bottom: none;
                flex-direction: column;

                .table-row__block__inner {
                    margin-bottom: 1rem;
                    position: relative;

                    &:before {
                        content: '';
                        height: calc(100% + 2rem);
                        width: 1px;
                        background: $color-gray;
                        position: absolute;
                        left: -2rem;
                        top: 0;
                    }

                   /* &:last-of-type {

                        &:before {
                            height: 100%;
                        }
                    }*/

                    &:first-of-type, &:only-of-type {

                        &:after {
                            content: '';
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background: $color-primary;
                            position: absolute;
                            top: calc(30px - 5px);
                            left: calc(-2rem - 5px);
                        }

                        &:before {
                            top: 30px;
                            height: calc(100% - 30px + 2rem);
                        }
                    }
                }


            }
        }

        &__reply {
            /*padding-left: calc(50px + 1rem);*/
            margin-top: 1rem;

            textarea {
                border-radius: 5px;
                background: $color-black-light-light;
                border: none;
                width: 100%;
                padding: 1rem;
                color: $color-white;
                font-family: $font-primary;
                margin-bottom: .4rem;

                &:focus {
                    outline: none;
                    background: $color-gray;
                }
            }
        }

        .table__column {
            margin-right: 1rem;
            display: flex;
            align-items: flex-start;
            flex-grow: 1;

            &:last-of-type {
                margin-right: 0;
            }

            &.control {
                width: 50px;
                flex-grow: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.thumb {
                flex-grow: 0;
            }

            .column__thumb {
                width: 50px;
                height: 50px;
                position: relative;
                overflow: hidden;
                border-radius: 5px;
            }

            .column__detail {
                align-self: center;
                width: 100%;

                &__left {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;

                    .inner {
                        display: flex;
                        align-items: center;

                        .item__stars {
                            margin: 0 2rem;
                        }
                    }
                }

                h3 {
                    margin: 0;
                    text-transform: unset;
                    font-size: 1.2rem;
                }

                h4 {
                    margin: 0;
                    text-transform: unset;
                    font-size: 1rem;
                }

                p {
                    font-size: .8rem;
                }

                &__controls {
                    display: flex;
                    margin-top: .6rem;

                    button {
                        margin-right: 1.6rem;

                        &.button-more {
                            font-size: .8rem;
                            color: $color-light;
                            font-weight: 400;
                        }

                        &.button-reply {
                            font-size: .8rem;
                            color: $color-light;
                            font-weight: 800;
                        }

                        span{
                            color: $color-light;
                        }
                    }
                }
            }

            .column__rating {
                min-width: 50px;
                text-align: right;

                button {
                    margin-right: 5px;
                }

                i {
                    color: $color-gold;
                }

                span {
                    color: $color-white;
                }
            }
        }
    }
}

@media (max-width: 1000px) {

    .table {

        &-header {
            flex-direction: column;
        }

        &-filter {
            margin-top: .6rem;
        }

        &-row {

            .column__detail {

                &__left {

                    .inner {
                        flex-direction: column;
                        align-items: flex-start !important;
                        margin-bottom: .4rem;

                        .item__stars {
                            margin-left: 0 !important;
                            margin-top: .2rem !important;
                        }
                    }
                }

                h3 {
                    font-size: .9rem !important;
                }

                h4 {
                    font-size: .7rem !important;
                }

                p {
                    font-size: .7rem !important;
                }
            }
        }
    }
}
