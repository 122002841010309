.popup-container {
  position: relative;

  .popup__window {
    position: absolute;
    width: 150px;
    left: calc(50% - 75px);
    bottom: calc(100% + 10px);
    background: $color-white;
    border-radius: 5px;
    padding: .7rem;
    text-align: center;

    p {
      color: $color-black;
      font-size: .8rem;
      font-weight: 600;
    }
  }

}