.performance-items {
  display: flex;
  flex-direction: column;
}

.performance-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  &__thumb {
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    width: 100px;
    height: 120px;
    margin-right: 1rem;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    .label {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }

  &:last-of-type, &:only-of-type {
    margin-bottom: 0;
  }

  .performance-item__content {
    flex-grow: 1;
  }

  .item__top {
    display: flex;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;

      &--left {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
      }


    }
  }

  .item__bottom {
    margin-top: .8rem;
  }

  .item__name {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .item__role {
    font-size: .7rem;
    color: $color-primary;
  }

  .item__date {
    font-size: .7rem;
  }

  .item__controls {
    display: flex;

    button {
      margin-right: 10px;

      i {
        color: $color-white;
      }

      &.active {

        i {
          color: $color-primary;
        }
      }
    }
  }

  .item__desc {
    font-size: .8rem;
    line-height: 1rem;
  }
}

@media (max-width: 768px) {

  .performance-item {

    &__thumb {
      width: 150px;

      .label {
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }

    .item__top {
      flex-direction: column;
    }

    .item__bottom {
      margin-top: 5px;
    }

    .item__name {
      font-size: 1.1rem;
      font-weight: 600;
    }

    .item__role {
      font-size: .7rem;
      color: $color-primary;
    }

    .item__date {
      font-size: .7rem;
    }

    .item__controls {
      display: flex;

      button {
        margin-right: 10px;

        i {
          color: $color-white;
        }

        &.active {

          i {
            color: $color-primary;
          }
        }
      }
    }

    .item__desc {
      font-size: .8rem;
      line-height: 1rem;
    }
  }
}