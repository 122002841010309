.search-container {
  width: 100%;
  background: $color-white;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  border-radius: 5px;
  z-index: 12;
  overflow: auto;
  box-shadow: 5px 5px 35px rgba(0, 0, 0, .2);

  .search__item {

    &__category {
      //text-align: center;
      background: $color-white-light-dark;

      h4 {
        padding: 0.4rem 0.4rem 0.4rem 1rem;
        color: $color-black;
        font-size: 1rem;
        font-weight: 600;
        position: relative;
        margin-bottom: 0;

        img {
          height: 20px;
          position: absolute;
          left: 1rem;
          top: 15px;
        }
      }
    }

    &__detail {
      display: flex;
      align-items: center;
      padding: 1rem;
      border-radius: 3px;
      border-bottom: 1px solid $color-white-light-dark;

      &:hover {
        background: $color-white-light-light;
      }

      &__thumb {
        width: 50px;
        height: 50px;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
      }

      &__content {
        margin-left: 1rem;

        h4 {
          color: $color-black;
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0;
        }

        p {
          color: $color-light;
          font-size: .8rem;
        }
      }
    }

    &__all {
      width: 100%;
      height: 40px;
      //padding: 1rem;
      color: $color-gray;
      font-size: .8rem;
      font-weight: 400;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;

      &:hover {
        background: $color-white-light-light;
      }

    }
  }
}

@media (max-width: 768px) {

  .search-container {
    max-height: calc(100vh - 60px - 4px);
    top: calc(100% + 4px);

    .search__item {

      &__thumb {
        width: 50px;
        height: 50px;
      }

      &__content {

        h4 {
          font-size: .8rem;
          margin-bottom: 3px;
        }

        p {
          font-size: .7rem;
        }
      }
    }

    .search__all {
      font-size: .7rem;

    }
  }
}