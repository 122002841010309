header {
    position: relative;
    z-index: 5;

    &.bb {
        border-bottom: 2px solid $color-black-light-light;
    }

    .header-container {
        display: flex;
        width: 100%;
        position: relative;
        //overflow: hidden;

        .header__meta {
            position: absolute;
            left: 30px;
            bottom: 10px;
            display: flex;
            align-items: center;
            z-index: 4;

            a {
                margin-right: 1rem;
            }

            span {
                font-size: .7rem;
                font-weight: 600;
                color: $color-light;
            }
        }

        &.l {

            .header__left, .header__right {
                width: 50%;
            }
        }

        &.s {

            .header__left {
                width: 240px;
            }

            .header__right {
                flex-grow: 1;
            }
        }

        .header__full {
            width: 100%;

            .header__img {
                height: 400px;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 400px;
                background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
                z-index: 1;
            }

            a {
                background: transparent;
                z-index: 3;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            .header__content {
                position: absolute;
                left: 30px;
                bottom: 30px;
                width: calc(100% - 60px - 120px);
                height: unset;
                padding: 0;
                z-index: 4;
            }
        }


        .header__img {
            width: 100%;
            height: 300px;
            margin-right: 1rem;
            flex-shrink: 0;
            position: relative;
            overflow: hidden;
            border-radius: 5px;

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }

        .header__content {
            height: 100%;
            padding: 0 0 0 2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .content__top {
                display: flex;
                margin-bottom: 1rem;

                &__left {
                    flex-grow: 1;
                    margin-right: 300px;
                }


                &__right {
                    position: absolute;
                    right: 0;
                    top: 0;

                    button {
                        width: 100%;
                        text-align: right;

                        i {
                            color: #fff;
                            font-size: 2rem;
                        }
                    }
                }
            }


            .header__img {
                width: 100%;
                height: 350px;
                margin-right: 1rem;
                flex-shrink: 0;
                position: relative;
                overflow: hidden;
                border-radius: 5px;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }

            .content__controls {

                a {
                    color: $color-white;
                    position: relative;
                    margin-right: 17px;

                    &:after {
                        content: '';
                    }
                }
            }

            .content__tags {
                display: flex;
                flex-wrap: wrap;

                span {
                    margin-right: .4rem;
                    margin-bottom: .6rem;
                }
            }

            .content__icons {
                display: flex;
                flex-direction: column;

                .icon__item {
                    display: flex;
                    font-size: .9rem;
                    color: $color-white;
                    position: relative;
                    margin-bottom: 10px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    i {
                        position: absolute;
                        top: 3px;
                        left: 0;
                    }

                    p {
                        margin-left: 22px;
                    }

                }
            }

            .content__name {
                margin-top: 4rem;

                h2 {
                    font-size: 2rem;
                    line-height: 2rem;
                    font-weight: 700;
                    margin-bottom: 1rem;
                }
            }

            .name__subheading {

                p {
                    font-size: .9rem;
                    margin-bottom: .4rem;
                }
            }

            .content__details {
                display: flex;
                flex-wrap: wrap;

                .details-item {
                    font-size: .8rem;
                    margin-bottom: .2rem;
                    margin-right: 2rem;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    span {

                        &:first-of-type {
                            font-weight: 700;

                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {


    header {

        &.bb {
            border-bottom: none;
        }

        .header-container {
            flex-wrap: wrap;
            margin-top: -20px;
            margin-left: -1rem;
            margin-right: -1rem;
            width: calc(100% + 2rem);
            padding: 0 1rem;

            &.l, &.s {

                .header__left, .header__right {
                    width: 100%;
                }
            }

            .header__left {
                z-index: 0;
            }

            .header__right {
                z-index: 1;
            }

            .header__full {

                .header__content {
                    width: calc(100% - 2rem) !important;
                    left: 1rem;
                }
            }

            .header__meta {
               left: 1rem;
            }

            .header__img {
                border-radius: 0;
                margin: 0 -1rem;
                width: auto;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 400px;
                    background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                }
            }

            .header__content {
                padding: 0;

                .content__top {
                    flex-direction: column;
                    margin-top: -200px;

                    &__left {
                        width: 100%;
                        margin-bottom: 40px;
                    }

                    &__right {
                        width: calc(100% - 2rem);
                        left: 1rem;
                        top: calc(100% + 20px);
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;
                    }

                    .content__controls {
                        display: none;
                    }

                    .content__name {
                        margin-top: 2rem;

                        h2 {
                            margin-bottom: 1rem;
                        }
                    }
                }

                .content__bottom {

                    .content__details {
                        flex-wrap: wrap;

                        .details-item {
                            width: calc(50% - 1rem);
                            margin-right: 2rem;
                            margin-top: .4rem;

                            &:nth-of-type(2n) {
                                margin-right: 0;
                            }

                            &:last-of-type {
                                margin-right: auto;
                            }

                            span {

                                &:first-of-type {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

