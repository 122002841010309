.auth-container {
  height: 100vh;
  width: 100vw;
  position: absolute;

  h1 {
    margin-top: 100px;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: center;

    a {
      color: $color-white;
      font-weight: 700;
    }
  }

  &__inner {
    background: $color-black-light-light;
    border-radius: 5px;
    padding: 2rem;
    width: 450px;
    margin-left: calc(50% - 225px);


    h2 {
      text-align: center;
      margin-bottom: 2rem;
      font-weight: 600;
    }

    p {
      font-size: .8rem;
      text-align: center;
    }

    a {
      font-weight: 600;
      color: $color-primary;
    }
  }
}

.auth-form {
  width: 100%;
}

@media (max-width: 768px) {

  .auth-container {
    position: relative;
    height: auto;
    width: auto;

    &__inner {
      padding: 1rem;
      width: 90%;
      margin-left: 5%;
      margin-top: 20px;
      margin-bottom: 20px;

      h1 {
        font-size: 1.4rem;
        margin-bottom: 1.4rem;
      }

      p {
        font-size: .7rem;
      }
    }
  }
}